import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { withPrefix } from "gatsby";
import "../styles/pages/index.scss";

class SiteIndex extends React.Component {
  render() {
    const pageName = "index";

    return (
      <Layout location={this.props.location} pageName={pageName}>
        <SEO
          title="Deft helps ship better software faster"
          lang="en"
          description="Deft helps technical executives at high-growth SaaS companies guide their web development teams to quicker delivery, with fewer bugs."
          keywords={[`web development`, `software`, `javascript`, `ruby`, `react`, `startup`]}
          ogPreviewImage={withPrefix("Deft-Logo-Color_800x450.png")}
          twitterPreviewImage={"https://deft.services"+withPrefix("Deft-Logo-Color_250x250.png")}
        />
      </Layout>
    )
  }
}

export default SiteIndex;
